import React, { FC, useEffect } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { Button } from 'wix-ui-tpa';
import { st, classes } from './Widget.st.css';

export type ControllerProps = {
  productId: string;
  sku: string;
  siblings: {
    title: string;
    originalUrl: string;
    size?: string;
    slug: string;
    sku: string;
    productOptions?: { [key: string]: { choices: { value: string }[] } };
  }[];
};

const Widget: FC<WidgetProps<ControllerProps>> = ({
  productId,
  sku,
  siblings = [],
}) => {
  const getSku = () => sku || '';

  useEffect(() => {
    let attempts = 0;
    const maxAttempts = 10;
    const interval = setInterval(() => {
      if (window.PriceSpider?.WidgetBinder) {
        window.PriceSpider.WidgetBinder.bind();
        clearInterval(interval);
      } else if (attempts >= maxAttempts) {
        clearInterval(interval);
        console.warn('PriceSpider.WidgetBinder not found after 10 seconds.');
      }
      attempts += 1;
    }, 1000);

    return () => clearInterval(interval);
  }, [sku]);

  return (
    <div className={st(classes.root, {})} data-hook="ProductCtas-wrapper">
      <div className={classes.psContainer}>
        <div className="ps-widget" ps-sku={getSku()}></div>
      </div>

      {siblings.length > 0 && (
        <div className={classes.siblingContainer}>
          <h4 className={classes.siblingTitle}>Also available in sizes:</h4>
          <div className={classes.siblingButtons}>
            {siblings.map((sibling) => {
              const sizeValue =
                sibling.productOptions?.Size?.choices?.[0]?.value || 'Unknown';

              return (
                <Button
                  key={sibling.slug}
                  onClick={() => {
                    const currentParams = window.location.search;
                    const siblingLink = `/product-page/${sibling.slug}${currentParams}`;
                    window.location.href = siblingLink;
                  }}
                  className={st(classes.siblingButton)}
                >
                  {sizeValue}
                </Button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Widget;
